import UiVideo from "@/components/UiVideo.vue";
import ConsentLayout from "@/layout/ConsentLayout.vue";
import utils from "@/common/utils";

export default {
    components: {
        "consent-layout": ConsentLayout,
        "cn-video": UiVideo,
    },

    data() {
        return {
            videos: [
                { id: this.$t('video_one'), imageSrc: "" },
                { id: this.$t('video_two'), imageSrc: "" },
                { id: this.$t('video_three'), imageSrc: "" },
                { id: this.$t('video_four'), imageSrc: "" },
                { id: this.$t('video_five'), imageSrc: "" },
            ]

        }
    },
    mounted() {
        this.videos.forEach((video) => {
            utils.getWistiaThumbnail(video.id).then((thumbnail_url) => {
                video.imageSrc = thumbnail_url;
            }).catch(err => {
                video.imageSrc = "dummyImageSrc";
                console.log("there was an error fetching the image for this video: " + err);
            });
        })
    },

}
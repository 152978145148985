// Animation
export const isPageAnimationEnabled = true;


// Login Page Style
export const isDefaultStyle = true; // true or false


//Images
export const logoPath = "logo.png";


//Pendo Analytics
export const pendoKey = '055e0a8b-b58d-43b8-6bc3-991ad9ea3974';
export const studyURL = "envision.longboat.com";
export const sponsorName = "Sarepta";
export const studyId = "SARE282";
export const AppType = "CN";
export const pendoAccountName = studyId + '_' + AppType;

// Authentication
export const password = "envisionCN#"
export const passwordReview = "envisionCN#"
export const cookieExpiryTimeInMins = 10


// i18n localisation configuration
export const defaultLocale = "en";
export const supportedLocales = {
    bd: "Belgisch-Nederlands",
    bf: "Français de Belgique",
    de: "Deutsch",
    en: "English",
    es: "Español",
    fr: "Français",
    it: "Italiano",
    ru: "Русский",
    sp: "Español-EE.UU",
    sv: "Svenska",

    /*    es: "Español",
       bd: "Belgisch-Nederlands",
       ck: "傳統中文",
       cs: "Čeština",
       ct: "繁體中文",
       de: "Deutsch",
       el: "ελληνικα",
       fc: "Français canadien",
       fr: "Français",
       hu: "Magyar",
       it: "Italiano",
       ko: "한국어",
       lp: "Español latinoamericano",
       ms: "Melayu",
       nl: "Nederlands",
       pl: "Polski",
       pt: "Português",
       ro: "Română",
       ru: "Русский",
       sp: "Español-EE.UU",
       th: "ไทย",
       zh: "简体中文",
       */
}
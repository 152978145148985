<template>
<div class="loading">

    <!-- Main -->
    <div class="video">
        <div class="video__thumbnail-wrapper">
            <span :class="
            'wistia_embed wistia_async_' +
            video +
            ' popover=true popoverContent=link'
          " style=" position: relative">
                <a href="#">
                    <img class="video__thumbnail" :src="videoImage" alt="" />
                    <span class="video__play-animator"></span>
                    <span class="video__play-link-wrapper"><i class="fas fa-play-circle video__play-icon"></i></span>
                </a>
            </span>
        </div>
        <div class="video__info">
            <p class="video__title">{{ videoTitle }}</p>
        </div>
    </div>
</div>
</template>


<script>
export default {
    data() {
        return {

        };
    },
    props: {
        video: {
            type: String,
            required: true,
        },
        videoTitle: {
            type: String,
            required: true,
        },
        videoImage: {
            type: String,
            required: true,
        },
    }
};
</script>


<style lang="scss">
.video__play-link-wrapper {

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    // border: solid 3px white;
    background: #ffffff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease;
}

.video__play-animator {
   // content: "";
   /*
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
   // transform: translate(0%, -100%);
    background: #bd4411;
    opacity: 0.5;
    
    
    display: flex;
    align-items: center;
    justify-content: center;


    
    width: 0%;
    height: 0%;
    transition: all 0.3s ease;
    */


position: absolute;
    top: 50%;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    background: #bd4411;
    opacity: 0.5;
    width: 0%;
    height: 0%;
    transition: all 0.3s ease;

}

.video__thumbnail-wrapper:hover {

    .video__play-animator {
        width: 100%;
        height: 100%;
    }

    .video__play-icon {
        color: white;
    }

    .video__play-link-wrapper {
        background: #bd4411;
    }

    /*
    .video__play-link-animation-wrapper {
        background: #da050557;
        border-radius: 0;
        width: 100%;
        height: 100%;
    }

    .video__play-icon {
        color: white;
    }
    */

}
</style>

<style lang="scss">
.video {
    display: flex;
    flex-direction: column;

    &__thumbnail-wrapper {
        border-bottom: 16px #bd4411 solid;
        box-shadow: -1px 1px 9px 2px rgb(189 69 16 / 12%);
        background-color: #eee;
    }

    &__thumbnail {
        vertical-align: middle;
        width: 100%;

        &.grayscale {
            /* IE */
            filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);

            /* Chrome, Safari */
            -webkit-filter: grayscale(1);

            /* Firefox */
            filter: grayscale(1);
        }
    }

    &__info {
        border-radius: 12px;
        background-color: #fff;
        position: relative;
        transition: all 0.3s ease;
        top: -10px;
        padding: 20px 30px;
        box-shadow: -1px 1px 9px 2px rgb(189 69 16 / 12%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        /*
        &:hover {
            background: #bd4510;

            .video__title {
                color: white;
            }

            .video__play-icon {
                color: white;
            }
        }
        */
    }

    &__title {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;
        color: #6b787c;
        width: 100%;
        text-align: left;
    }

    &__play-icon {
        font-size: 50px;
        color: #bd4510;
        transition: all 0.3s ease;
    }
}

@media screen and (min-width: 1000px) {
    .video {
        flex-direction: row;

        &__thumbnail-wrapper {
            width: 55%;
            position: relative;
            right: -5px;
            border-bottom: 8px #bd4411 solid;
        }

        &__info {
            width: 45%;
            top: 0;
            left: -5px;
        }
    }
}
</style>

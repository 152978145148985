import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import consent from "@/common/consent";

import {
    isPageAnimationEnabled,
} from "@/config";
import handleScrollAnimation from "@/common/scroll-reveal";

Vue.use(VueAxios, axios);


var utils = {
    async addWistiaScript() {
        let wistiaJs = document.createElement("script");
        wistiaJs.setAttribute(
            "src",
            "https://fast.wistia.com/assets/external/E-v1.js"
        );
        wistiaJs.setAttribute("id", "vscript");
        document.head.appendChild(wistiaJs);
    },


    resizeOverlay(elementID) {
        resize();

        function resize() {
            let element = document.getElementById(elementID);
            if (element !== null) {
                element.style.height = window.innerHeight + "px";
            }
        }

        window.addEventListener(
            "resize",
            function() {
                resize();
            },
            true
        );
    },

    wistiaCookieTracking(consent) {
        window._wq = window._wq || [];
        window._wq.push(function(W) {
            W.consent(consent);
            //console.log("Wistia: In privacy mode?", !W.consent()); // returns true or false
        });
    },

    async getWistiaThumbnail(wistiaID) {
        let url =
            "https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/" +
            wistiaID +
            "?embedType=async&videoWidth=640";
        const res = await Vue.axios.get(url);
        const data = await res.data;
        let thumbnail_url = data.thumbnail_url + "&" + "image_resize=" + 640;
        return thumbnail_url;
    },

    generateRandomId() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    },

    playAnimation(idElement, elementSelector) {
        let waitTimeInSecs = 15;
        setTimeout(function() {
            var element = document.getElementById(idElement);
            element.classList.remove(elementSelector);
        }, waitTimeInSecs * 60);
    },

    throttle(callback, time) {
        //initialize throttleTimer as false
        let throttleTimer = false;

        //don't run the function while throttle timer is true
        if (throttleTimer) return;

        //first set throttle timer to true so the function doesn't run
        throttleTimer = true;

        setTimeout(() => {
            //call the callback function in the setTimeout and set the throttle timer to false after the indicated time has passed
            callback();
            throttleTimer = false;
        }, time);
    },


    configurePageAnimation() {
        if (isPageAnimationEnabled && screen.width > 1000) {
            // disable page animation in screens 1000px and above
            let element = document.getElementById("app");
            element.classList.add("animate");
            this.playAnimation("app", "animate");
        }
    },

    configureOnScrollAnimation() {
        if (screen.width > 1000) {
            let element = document.getElementById("js-video-wrapper");
            element.classList.add("scroll-animate");
            window.addEventListener("scroll", () => {
                this.throttle(handleScrollAnimation, 250);
            });
        }
    },

    addlocaleToUrl(context, locale) {
        context.$router.push({
                path: window.location.pathname,
                query: {
                    lang: locale
                }
            },
            () => {}
        ); // the second .push value "() => {}" prevents the error message on console
    },

    configureConsent(consent) {
        if (Vue.$cookies.get('CNcookies') === "false") {
            consent.setStatus(false);
        }
        if (Vue.$cookies.get('CNcookies') === "true") {
            consent.setStatus(true);
        }
        // console.log("Utils::consent status is: " + consent.status);
    },

    handleLocaleChangeEvent(context, locale) {
        utils.addlocaleToUrl(context, locale);
        if (consent.status) {
            console.log("this is the route: " + context.$route.fullPath);
        }
    }
};

export default utils;
import Vue from 'vue'
import App from '@/App.vue'
import i18n from '@/plugins/i18n'
import router from '@/routes'
import VueCookies from 'vue-cookies'
import StoragePlugin from 'vue-web-storage';
import '@/pendo.js';



Vue.use(VueCookies);

Vue.use(StoragePlugin, {
    prefix: '',
    drivers: ['session', 'local'], // default 'local'
});


Vue.config.productionTip = false;

new Vue({
    i18n,
    router,
    render: h => h(App)
}).$mount('#app');